.body, .dashboard, .ant-card-body {
    background: #f5f6f8;
}

.custom-checkbox .ant-checkbox-group-item.ant-checkbox-wrapper {
    display: block;
    padding: .5em;
    margin-bottom: .3em;
    font-size: 1.1em;
    background: white;
    border: 1px solid #e9e9e9;
}

.loginCard {
    background: #f5f6f8 !important;
}